








import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import Day from '@/modules/common/types/day.type';
import ASSESSMENT_TYPES from '@/modules/common/constants/assessments-types.constant';

import MarketsService, { MarketsServiceS } from '@/modules/markets/markets.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import MarketsFiltersService, { MarketsFiltersServiceS } from '@/modules/markets/markets-filters.service';
import CiTable, {
    ITableConfig, ITableData, DATA_TYPE, ICell,
} from '@/modules/common/components/ci-table';

@Component({
    components: {
        CiTable,
    },
})
export default class MarketsTable extends Vue {
    @Inject(MarketsServiceS) private marketsService!: MarketsService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(HotelsServiceS) private hotelsService!: HotelsService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(MarketsFiltersServiceS) private marketsFiltersService!: MarketsFiltersService;

    private getPosition(day: Day, hotelId: number): number | null {
        return this.marketsService.getPosition(day, hotelId);
    }

    private getNumberOfHotels(day: Day, hotelId: number): number | null {
        return this.marketsService.getNumberOfHotels(day, hotelId) || null;
    }

    private handleRowClick(day: Day) {
        if (this.userService.currentHotelId) {
            this.$router.push({
                name: `${this.$route.name!}.day-markets`,
                params: { hotelId: this.userService.currentHotelId.toString(), day: String(day) },
            });
        }
    }

    private positionColor(day: Day, hotelId: number) {
        const color = this.marketsService.getTableAssessment(day, hotelId);

        if (color === ASSESSMENT_TYPES.BAD) {
            return '#E7472D';
        }

        if (color === ASSESSMENT_TYPES.GOOD) {
            return '#01B875';
        }

        return null;
    }

    private transformDate(day: number): string {
        const { month, year } = this.documentFiltersService.storeState.settings;
        const d = new Date(year, month, day);
        const dayName = d.toLocaleDateString('en-US', { weekday: 'short' }).toLowerCase();

        return `${this.$t(dayName)} ${day}/${month + 1 < 10 ? 0 : ''}${month + 1}`;
    }

    private get source() {
        return this.marketsFiltersService.currentProvider;
    }

    get mvTableConfig(): ITableConfig {
        return {
            height: '700px',
            width: '100%',
            cellSize: [{
                width: ['90px', '120px', '140px', '200px'],
                height: ['50px'],
            }, {
                width: ['200px'],
                height: ['50px'],
            }],
        };
    }

    private getCellData(day: Day, hotelId: number) {
        const data = {
            position: '',
            style: {},
            hasData: false,
        };

        if (!this.source) {
            return data;
        }

        if (this.marketsService.isOutOfRange()) {
            data.position = 'Out Of Range';
            data.style = { color: 'grey' };
            return data;
        }

        if (this.marketsService.isNoData(day, this.source)) {
            data.position = 'No Data';
            data.style = { color: 'grey' };
            return data;
        }

        if (this.marketsService.isNA(day, this.source, hotelId)) {
            data.position = 'N/A';
            data.style = { color: 'grey' };
            return data;
        }

        if (this.marketsService.isSoldOut(day, hotelId, this.source)) {
            data.position = 'Sold Out';
            data.style = { color: 'grey' };
            return data;
        }

        data.hasData = true;
        data.position = String(this.getPosition(day, hotelId));
        data.style = { color: this.positionColor(day, hotelId) };

        return data;
    }

    get mvTableData(): ITableData {
        const { currentHotelId } = this.userService;
        const competitors = this.compsetsService.competitors || [];
        const { days } = this.documentFiltersService;

        if (!currentHotelId) {
            return [];
        }

        return [{
            isSticky: true,
            columns: [
                {
                    title: 'Date',
                    data: days.map(day => ({
                        value: this.transformDate(day),
                        onClick: () => this.handleRowClick(day),
                    })),
                }, {
                    title: 'Events',
                    dataType: DATA_TYPE.EVENT,
                    data: days.map(day => ({
                        onClick: () => this.handleRowClick(day),
                    })),
                }, {
                    title: 'Hotels in market',
                    data: days.map(day => {
                        const hotelsNumber = this.getNumberOfHotels(day, currentHotelId);
                        return {
                            value: hotelsNumber ? String(hotelsNumber) : '',
                            onClick: () => this.handleRowClick(day),
                        };
                    }),
                }, {
                    title: this.hotelsService.getHotelName(currentHotelId),
                    titleStyle: { color: '#00759e' },
                    data: days.map(day => {
                        const {
                            position, hasData,
                        } = this.getCellData(day, currentHotelId);
                        return {
                            value: `${hasData ? '#' : ''}${position}`,
                            onClick: () => this.handleRowClick(day),
                        } as ICell;
                    }),
                },
            ],
        }, {
            columns: competitors.map(competitorId => ({
                title: this.hotelsService.getHotelName(competitorId),
                data: days.map(day => {
                    const {
                        position, style, hasData,
                    } = this.getCellData(day, competitorId);
                    return {
                        value: `${hasData ? '#' : ''}${position}`,
                        style,
                        onClick: () => this.handleRowClick(day),
                    } as ICell;
                }),
            })),
        }];
    }
}
